<template>
  <workbenchbox>
    <div v-loading="loading" style="height:calc(100vh - 100px);overflow: hidden;" :element-loading-text="config.loading.text" :element-loading-spinner="config.loading.spinner" :element-loading-background="config.loading.background" class="dp-f pt-12">
      <choose style="height:calc(100vh - 112px)" class="mr-20" :index="1" @Emit="butemit" :Data="[{title:'全部'},{title:'待我处理'},{title:'待他人处理'},{title:'签署完成'},{title:'已过期'},{title:'草稿箱'},{title:'抄送我的'},{title:'合同存证'}]"></choose>
      <list v-if="state.butIndex < 6" style="overflow: auto;" :butIndex="state.butIndex" ref="listRef"></list>
      <evidlist v-if="state.butIndex >= 6" style="overflow: auto;" :butIndex="state.butIndex" ref="listRef" />
    </div>
  </workbenchbox>
</template>
<script setup>
import { reactive, ref, unref,nextTick } from "vue";
import choose from "@/components/sidebar/choose.vue";
import workbenchbox from "@/components/layout/workbenchbox.vue";
import list from "./list.vue"//签署管理
import evidlist from "./evidlist.vue";
const loading = ref(false); //loading 显示
const listRef=ref()
const state = reactive({
  butIndex: 1, //按钮选中下标
});
// 按钮返回
const butemit = (el) => {
  // console.log('按钮返回',el)
  state.butIndex = el;
  nextTick(()=>{
    unref(listRef).resetsearchKey()
    unref(listRef).getDataList()
  })
};
</script>
<style lang="scss" scoped >
@import "@/styles/general/element.scss"; //element 样式重置
@import "@/styles/general/element/table.scss"; //element 列表样式重置
@import "@/styles/general/element/pagination1.scss"; //element 分页样式重置
</style>